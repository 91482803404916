import { flux } from '@/core/flux.module';
import { PUSH_WORKBENCH } from '@/core/flux.service';
import { ChatMessage } from '@/aiAssistant/aiAssistant.types';

export function setAIAssistantDisplayed(displayed: boolean) {
  flux.dispatch('AI_ASSISTANT_DISPLAYED', { displayed }, PUSH_WORKBENCH);
}

export function setAIAssistantMaximized(maximized: boolean) {
  flux.dispatch('AI_ASSISTANT_MAXIMIZED', { maximized }, PUSH_WORKBENCH);
}

export function setAIAssistantMessages(messages: ChatMessage[]) {
  flux.dispatch('AI_ASSISTANT_SET_MESSAGES', { messages }, PUSH_WORKBENCH);
}
