import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { coldarkCold, coldarkDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { Icon } from '@seeqdev/qomponents';
import { useFluxPath } from '@/core/hooks/useFluxPath.hook';
import { sqWorkbenchStore } from '@/core/core.stores';
import { Link } from 'react-router-dom';

interface MarkdownSnippetProps {
  id: string;
  markdown: string;
  insertFormulaSnippet: (snippet: string) => void;
}

export const MarkdownSnippet: React.FunctionComponent<MarkdownSnippetProps> = ({
  id,
  markdown,
  insertFormulaSnippet,
}) => {
  const darkMode = useFluxPath(sqWorkbenchStore, () => sqWorkbenchStore.darkMode);

  const coldarkStyle = darkMode ? coldarkDark['pre[class*="language-"]'] : coldarkCold['pre[class*="language-"]'];
  coldarkStyle['margin'] = 0;
  coldarkStyle['borderBottomRightRadius'] = '6px';
  coldarkStyle['borderBottomLeftRadius'] = '6px';

  return (
    <div id={id}>
      <ReactMarkdown
        components={{
          code({ node, inline, className, children, ...props }) {
            const match = /language-(\w+)/.exec(className || '');
            let displayLanguage = match ? match[1] : '';
            let parsingLanguage = match ? match[1] : 'text';
            if (match && match[1] === 'seeq') {
              displayLanguage = 'seeq formula';
              parsingLanguage = 'php';
            }

            return !inline && match ? (
              <>
                <div className="flexColumnContainer codeHeader p8">
                  <span className="flexFill">{displayLanguage}</span>
                  <Icon
                    icon="fc-copy"
                    tooltip="Copy"
                    extraClassNames="cursorPointer pt1"
                    testId="exampleCopyBtn"
                    onClick={() => insertFormulaSnippet(String(children))}
                  />
                </div>
                <div className="flexColumnContainer mb15">
                  <SyntaxHighlighter
                    {...props}
                    style={darkMode ? coldarkDark : coldarkCold}
                    PreTag="div"
                    wrapLongLines={true}
                    language={parsingLanguage}
                    customStyle={{ minWidth: '100%' }}>
                    {String(children).replace(/\n$/, '')}
                  </SyntaxHighlighter>
                </div>
              </>
            ) : (
              <code {...props} className={className}>
                {children}
              </code>
            );
          },
          a: (props) => {
            const origin = window.origin;
            if (props.href && props.href.startsWith(window.origin)) {
              const url = props.href.substring(origin.length);
              return <Link to={url}>{props.children}</Link>;
            } else {
              return (
                <a href={props.href} target="_blank" rel="noopener">
                  {props.children}
                </a>
              ); // All other links
            }
          },
        }}
        remarkPlugins={[remarkGfm]}>
        {markdown}
      </ReactMarkdown>
    </div>
  );
};
