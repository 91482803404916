import { AgentSelector } from '@/aiAssistant/AgentSelector.atom';
import { sqWorkstepsStore } from '@/core/core.stores';
import { aiAssistantEnabled } from '@/services/systemConfiguration.utilities';
import { WORKSTEP_SCHEMA_VERSION } from '@/worksteps/worksteps.constant';
import { Button, Icon } from '@seeqdev/qomponents';
import { t } from 'i18next';
import React, { ChangeEventHandler, KeyboardEventHandler, useState } from 'react';
import TextAreaAutosize from 'react-textarea-autosize';
import { agents, AgentType, AssistantOrigin } from './aiAssistant.types';
import _ from 'lodash';
import { doTrack } from '@/track/track.service';
import { KEY_CODES } from '@/main/app.constants';
import { SpeechToText } from './SpeechToText.atom';

interface AiAssistantPromptProps {
  submitPrompt: (
    prompt: string,
    origin: AssistantOrigin,
    context?: Record<string, unknown>,
    messageId?: string,
    voicePrompt?: boolean,
  ) => void;
  isRunning: boolean;
  origin: AssistantOrigin;
  abortRequest: () => void;
  onAgentChange?: (agent: AgentType) => void;
  selectedAgent: AgentType;
}

export const AiAssistantPrompt: React.FunctionComponent<AiAssistantPromptProps> = ({
  submitPrompt,
  abortRequest,
  isRunning,
  origin,
  onAgentChange,
  selectedAgent,
}) => {
  const [validPrompt, setValidPrompt] = useState(false);
  const [isVoicePrompt, setIsVoicePrompt] = useState(false);
  const [prompt, setPrompt] = useState('');

  const [forceStopListening, setForceStopListening] = useState(false);

  const onChange: ChangeEventHandler<HTMLTextAreaElement> = (event) => {
    const promptValue = event.target.value;
    setValidPrompt(promptValue.trim().length > 0);
    setPrompt(promptValue);
  };
  const setVoicePrompt = (voicePrompt: string) => {
    setValidPrompt(voicePrompt.trim().length > 0);
    setPrompt(voicePrompt);
    setIsVoicePrompt(true);
  };

  const disableSubmit = () => {
    return isRunning || !validPrompt || !aiAssistantEnabled();
  };

  const onSubmit = () => {
    setForceStopListening(true);
    const context = sqWorkstepsStore.current.id
      ? { ...sqWorkstepsStore.current, version: WORKSTEP_SCHEMA_VERSION }
      : undefined;
    submitPrompt(prompt, origin, context, undefined, isVoicePrompt);
    setPrompt('');
  };

  const catchEnterToSubmit: KeyboardEventHandler = (e) => {
    if (!disableSubmit()) {
      if (e.keyCode === KEY_CODES.ENTER && !(e.ctrlKey || e.metaKey || e.shiftKey)) {
        e.preventDefault();
        const context = sqWorkstepsStore.current.id
          ? { ...sqWorkstepsStore.current, version: WORKSTEP_SCHEMA_VERSION }
          : undefined;
        submitPrompt(prompt, origin, context);
        setPrompt('');
      }
      if (e.keyCode === KEY_CODES.ENTER && (e.ctrlKey || e.metaKey || e.shiftKey)) {
        setPrompt(`${prompt}\r\n`);
      }
    }
  };

  const agent = _.find(agents, { key: selectedAgent }!)!;

  return (
    <div className="flexColumnContainer flexAlignCenter flexAlignEnd p10 promptFooter pt20">
      <div className="flexColumnContainer promptInput flexFill flexAlignEnd p4">
        <div className="agentSelectorWrapper flexColumnContainer flexAlignEnd height-maximum mb5">
          {onAgentChange ? (
            <AgentSelector
              selectedAgentId={selectedAgent || 'general'}
              showSelected={true}
              allowUpdate={true}
              onAgentChange={onAgentChange!}
              isRunning={isRunning}
            />
          ) : (
            <Icon icon={agent.icon} extraClassNames="pl5 pr2" type="color" color={agent.color} />
          )}
        </div>
        <TextAreaAutosize
          value={prompt}
          className={`flexFill p5 ${isRunning ? 'disabled' : ''}`}
          placeholder={
            isRunning
              ? t('FORMULA.ASSISTANT.RESPONDING_PROMPT_PLACEHOLDER')
              : t(`FORMULA.ASSISTANT.PROMPT_PLACEHOLDER.${selectedAgent.toUpperCase()}`)
          }
          maxRows={7}
          minRows={1}
          onChange={onChange}
          onKeyDown={catchEnterToSubmit}
          data-testid="promptInput"
        />

        <SpeechToText
          setPrompt={setVoicePrompt}
          stopListening={forceStopListening}
          resetStopListening={setForceStopListening}
        />

        <div className="flexColumnContainer flexAlignEnd height-maximum">
          {isRunning && (
            <Button
              variant="outline"
              onClick={() => {
                doTrack('AiAssistant', 'response aborted');
                abortRequest();
              }}
              size="sm"
              testId="abortBtn"
              extraClassNames="pl8"
              iconPrefix={'fa-solid'}
              icon={'fa-stop fa-beat sq-text-color'}
              tooltip={t('AI_ASSISTANT.ABORT_TOOLTIP')}
            />
          )}
          {!isRunning && (
            <Button
              variant="theme"
              onClick={onSubmit}
              disabled={disableSubmit()}
              size="sm"
              testId="submitPrompt"
              extraClassNames="pl8"
              icon="fa-paper-plane sq-icon-white mn5"
            />
          )}
        </div>
      </div>
    </div>
  );
};
