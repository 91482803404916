import React, { Dispatch, SetStateAction } from 'react';
import { animated, useSpring } from '@react-spring/web';
import { Icon } from '@seeqdev/qomponents';
import { LevelOfSatisfaction } from '@/aiAssistant/aiAssistant.types';

interface HoveredIconComponentProps {
  left: number;
  icon: string;
  satisfaction: LevelOfSatisfaction;
  setSatisfaction: Dispatch<SetStateAction<LevelOfSatisfaction | undefined>>;
}

export const HoveredIconComponent: React.FunctionComponent<HoveredIconComponentProps> = ({
  left,
  icon,
  satisfaction,
  setSatisfaction,
}) => {
  const startingSize = 16;
  const scaledSize = 20;
  const config = {
    tension: 200,
    friction: 80,
    mass: 1,
  };
  const [springs, api] = useSpring(() => ({
    from: { fontSize: startingSize },
  }));

  const onMouseEnter = () => {
    api.start({
      from: {
        fontSize: startingSize,
      },
      to: {
        fontSize: scaledSize,
      },
      config,
      reverse: true,
    });
  };
  return (
    <div className="positionAbsolute" style={{ left }} onClick={() => setSatisfaction(satisfaction)}>
      <animated.div style={{ ...springs }} onMouseEnter={onMouseEnter}>
        <Icon icon={icon} extraClassNames="cursorPointer themeOnHover" type="dark-gray" />
      </animated.div>
    </div>
  );
};
