import React from 'react';
import { useTranslation } from 'react-i18next';

export const AiAssistantInformation: React.FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <div className="flexRowContainer pl30 pr30 pb15 overflowAuto">
      <div className="flexRowContainer flexCenter">
        <div className="pointInTime">{t('AI_ASSISTANT.INFORMATION.ABOUT')}</div>
        <div className="pt5">{t('AI_ASSISTANT.INFORMATION.ABOUT_TEXT_1')}</div>
        <div className="pt5">{t('AI_ASSISTANT.INFORMATION.ABOUT_TEXT_2')}</div>
      </div>

      <div className="flexRowContainer">
        <div className="pointInTime pt20 text-center">{t('AI_ASSISTANT.INFORMATION.DATA_PRIVACY')}</div>
        <div className="pt5">{t('AI_ASSISTANT.INFORMATION.DATA_PRIVACY_TEXT_1')}</div>
        <div className="pt5">{t('AI_ASSISTANT.INFORMATION.DATA_PRIVACY_TEXT_2')}</div>
        <ul>
          <li>
            <div className="pt5">{t('AI_ASSISTANT.INFORMATION.DATA_PRIVACY_TEXT_3')}</div>
          </li>
          <li>
            <div className="pt5">{t('AI_ASSISTANT.INFORMATION.DATA_PRIVACY_TEXT_4')}</div>
          </li>
        </ul>
      </div>

      <div className="flexRowContainer flexCenter">
        <div className="pointInTime pt20">{t('AI_ASSISTANT.INFORMATION.DATA_OWNERSHIP')}</div>
        <div className="pt5">{t('AI_ASSISTANT.INFORMATION.DATA_OWNERSHIP_TEXT')}</div>
      </div>

      <div className="flexRowContainer flexCenter">
        <div className="pointInTime pt20">{t('AI_ASSISTANT.INFORMATION.SECURITY')}</div>
        <div className="pt5">{t('AI_ASSISTANT.INFORMATION.SECURITY_TEXT')}</div>
      </div>

      <div className="flexRowContainer flexCenter">
        <div className="pointInTime pt20">{t('AI_ASSISTANT.INFORMATION.ABUSE')}</div>
        <div className="pt5">{t('AI_ASSISTANT.INFORMATION.ABUSE_TEXT')}</div>
      </div>
    </div>
  );
};
