import { Modal, TextField } from '@seeqdev/qomponents';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { IdentityOption, SelectIdentity } from '@/core/SelectIdentity.molecule';

interface AiAssistantShareProps {
  selectedChatName: string;
  onClose: () => void;
  shareChat: (name: string, userId: string) => void;
}

export const AiAssistantShare: React.FunctionComponent<AiAssistantShareProps> = ({
  selectedChatName,
  onClose,
  shareChat,
}) => {
  const { t } = useTranslation();
  const [selectedUser, setSelectedUser] = React.useState({} as IdentityOption);
  const [chatName, setChatName] = React.useState(selectedChatName);

  React.useEffect(() => {
    setChatName(selectedChatName);
  }, [selectedChatName]);

  const handleClose = () => {
    onClose();
    setSelectedUser({} as IdentityOption);
  };

  return (
    <Modal
      onClose={handleClose}
      onSubmit={() => {
        shareChat(chatName, selectedUser.id);
        handleClose();
      }}
      disableSubmitButton={!chatName || !selectedUser?.id}
      open={true}
      size="lg"
      title={t('AI_ASSISTANT.SHARE.TITLE')}
      titleIcon="fa-solid fa-share-from-square"
      titleIconPosition="right"
      subtitle={t('AI_ASSISTANT.SHARE.SUBTITLE')}
      submitButtonLabel={t('AI_ASSISTANT.SHARE.BUTTON')}
      cancelButtonLabel={t('CANCEL')}>
      <div>
        <div className="form-group">
          <label htmlFor="user">{t('AI_ASSISTANT.SHARE.NAME.LABEL')}</label>
          <TextField
            extraClassNames="width-maximum"
            placeholder={t('AI_ASSISTANT.SHARE.NAME.PLACEHOLDER')}
            value={chatName}
            onChange={({ target: { value } }) => setChatName(value)}
          />
        </div>
        <div className="form-group mt15" style={{ height: '200px' }}>
          <label htmlFor="user">{t('AI_ASSISTANT.SHARE.USER.LABEL')}</label>
          <SelectIdentity
            idForLabel="user"
            identity={selectedUser}
            setIdentity={setSelectedUser}
            allowGroups={false}
            startEditable={true}
            placeholder="AI_ASSISTANT.SHARE.USER.PLACEHOLDER"
          />
        </div>
      </div>
    </Modal>
  );
};
