import { ValueOf } from '@/utilities.types';
import enJson from 'resources/i18n/en.json?url';
import esLAJson from 'resources/i18n/es-LA.json?url';
import frFRJson from 'resources/i18n/fr-FR.json?url';
import deDEJson from 'resources/i18n/de-DE.json?url';
import itITJson from 'resources/i18n/it-IT.json?url';
import jaJPJson from 'resources/i18n/ja-JP.json?url';
import koKOJson from 'resources/i18n/ko-KO.json?url';
import ptBRJson from 'resources/i18n/pt-BR.json?url';
import zhCNJson from 'resources/i18n/zh-CN.json?url';

export const LOCALES = {
  EN: 'en',
  ES: 'es-LA',
  DE: 'de-DE',
  FR: 'fr-FR',
  IT: 'it-IT',
  JA: 'ja-JP',
  KO: 'ko-KO',
  PT: 'pt-BR',
  ZH: 'zh-CN',
} as const;
export type LocaleKey = ValueOf<typeof LOCALES>;

/**
 * For the language selector in the user profile modal, we do not want to translate the text since we want each
 * language label to be in its respective language.
 */
type LocaleData = { key: LocaleKey; text: string; translationPath: any };
export const LOCALE_DATA: LocaleData[] = [
  { key: LOCALES.EN, text: 'English', translationPath: enJson },
  { key: LOCALES.ES, text: 'Español', translationPath: esLAJson },
  { key: LOCALES.FR, text: 'Français', translationPath: frFRJson },
  { key: LOCALES.DE, text: 'Deutsch', translationPath: deDEJson },
  { key: LOCALES.IT, text: 'Italiano', translationPath: itITJson },
  { key: LOCALES.JA, text: '日本語', translationPath: jaJPJson },
  { key: LOCALES.KO, text: '한국어', translationPath: koKOJson },
  { key: LOCALES.PT, text: 'Português', translationPath: ptBRJson },
  { key: LOCALES.ZH, text: '普通话', translationPath: zhCNJson },
];
