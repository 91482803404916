import { InitializeMode, PersistenceLevel, Store } from '@/core/flux.service';
import { ChatMessage } from '@/aiAssistant/aiAssistant.types';

interface AIAssistantState {
  displayed: boolean;
  maximized: boolean;
  messages: ChatMessage[];
}

export class AiAssistantStore extends Store {
  static readonly storeName = 'sqAiAssistantStore';
  persistenceLevel: PersistenceLevel = 'WORKBENCH';

  initialize(initializeMode: InitializeMode) {
    this.state = this.immutable({ displayed: false, lastSubmittedPrompt: '', maximized: false });
  }

  get displayed() {
    return this.state.get('displayed');
  }

  get maximized() {
    return this.state.get('maximized');
  }

  get messages() {
    return this.state.get('messages') || [];
  }

  /**
   * Exports state so it can be used to re-create the state later using `rehydrate`.
   *
   * @returns {Object} The dehydrated items.
   */
  dehydrate() {
    return this.state.serialize();
  }

  /**
   * Re-creates the AI Store. All necessary data needed to rehydrate is persisted so no actions have to be
   * called.
   *
   * @param {Object} dehydratedState Previous state usually obtained from `dehydrate` method.
   */
  rehydrate(dehydratedState: AIAssistantState) {
    this.state.merge(dehydratedState);
  }

  protected readonly handlers = {
    /**
     * Sets the displayed flag
     */
    AI_ASSISTANT_DISPLAYED: (payload: { displayed: boolean }) => {
      this.state.set('displayed', payload.displayed);
    },

    /**
     * Sets the maximized flag
     */
    AI_ASSISTANT_MAXIMIZED: (payload: { maximized: boolean }) => {
      this.state.set('maximized', payload.maximized);
    },

    AI_ASSISTANT_SET_MESSAGES: (payload: { messages: ChatMessage[] }) => {
      this.state.set('messages', payload.messages);
    },
  };
}
